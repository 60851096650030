export const useSallyFetch: typeof useFetch = (request, opts?: any) => {
  const config = useRuntimeConfig()
  const token = useCookie('token')
  const isApigee = opts?.isApigee || false
  const defaultBaseURL = opts?.baseURL ?? config.public.baseApiUrl
  const baseURL = isApigee ? '' : defaultBaseURL

  return useFetch(request, {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
    baseURL,
    ...opts,
  })
}
